import css from 'css';
import { authorizedFetch } from '../../utils';
import * as itemTypes from '../../items/item-types';

// generate a css blob from state
export const generateCssBlob = state => {
  let cssBlob = '';
  const propertiesToRemove = ['isEditing'];

  const output = Object.keys(state).reduce((obj, key) => {
    if (!propertiesToRemove.includes(key)) {
      obj[key] = state[key];
    }
    return obj;
  }, {});

  for (const item of Object.values(output)) {
    if (Array.isArray(item.css)) {
      for (const rule of item.css) {
        cssBlob += `${rule.selector} { ${rule.properties} }`;
      }
    } else if (typeof item.css === 'object') {
      cssBlob += `${item.css.selector} { ${item.css.properties} }`;
    }
  }

  return cssBlob;
};

// convert a css blob into an array of objects
export const parseCssBlob = cssBlob => {
  const parsed = css.parse(cssBlob);
  const parsedCss = [];

  for (const rule of parsed.stylesheet.rules) {
    const declarations = rule.declarations;
    const selectors = rule.selectors;

    for (const selector of selectors) {
      const properties = {};

      for (const declaration of declarations) {
        const property = declaration.property;
        const value = declaration.value;

        properties[property] = value;
      }

      parsedCss.push({
        selector,
        properties
      });
    }
  }
  return parsedCss;
};

// set state from a css blob
export const setStateFromCssBlob = (cssTheme, customCss, cssBlob) => {
  if (typeof cssTheme === 'string') {
    const matchingObj = cssBlob.find(
      prop => prop.selector === cssTheme && prop.properties[customCss]
    );

    if (matchingObj) {
      return [
        {
          css: {
            selector: cssTheme,
            properties: `${customCss}: ${matchingObj.properties[customCss]};`
          }
        }
      ];
    } else {
      return [];
    }
  } else {
    const matchedItems = cssTheme.reduce((items, item) => {
      const matchingObj = customCss.find(obj => {
        const cssProp = Array.isArray(item.css) ? item.css[0] : item.css;
        return (
          obj.selector === cssProp.selector &&
          Object.entries(obj.properties).every(([key, value]) =>
            cssProp.properties.includes(`${key}: ${value}`)
          )
        );
      });

      if (matchingObj) {
        items.push({
          displayName: item.displayName,
          field: item.field,
          css: item.css
        });
      }
      return items;
    }, []);

    return matchedItems;
  }
};

// parse css properties from a string
export const parseCSSProperties = cssString => {
  // Regular expression to match any string between ":" and ";"
  // eslint-disable-next-line no-useless-escape
  const stringRegex = /:(.*)\;/;
  const match = cssString.match(stringRegex);

  if (match && match.length > 1) {
    return match[1].trim();
  } else {
    return null;
  }
};

// read a file as a data url
export const readFile = file => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });
};

// fetch ad unit templates
export const fetchAdUnitTemplates = async (
  itemId,
  setAdUnitTemplates,
  addError
) => {
  try {
    const response = await authorizedFetch(
      `/api/trafficpartner/${itemId}/embeddedflowconfig/adunittemplates`,
      'GET'
    );
    setAdUnitTemplates(response);
  } catch (error) {
    addError('Failed to fetch ad unit templates. Try again later.');
  }
};

// fetch custom ad unit templates
export const fetchCustomAdUnitTemplates = async (
  setCustomAdUnitTemplates,
  addError
) => {
  try {
    const response = await authorizedFetch(`/api/CustomAdUnitTemplate`, 'GET');
    setCustomAdUnitTemplates(response);
  } catch (error) {
    addError('Failed to fetch custom ad unit templates. Try again later.');
  }
};

export async function uploadVirtualCurrencyIcon(
  url,
  trafficPartnerId,
  virtualCurrencyObj,
  version,
  dispatch,
  modifyItemAction
) {
  const formData = new FormData();
  const imageData = await fetch(url).then(r => r.blob());
  formData.append('file', imageData);
  formData.append('type', 'icon');
  formData.append('expectedVersion', version);
  try {
    const response = await authorizedFetch(
      `/api/${itemTypes.TRAFFIC_PARTNER}/${trafficPartnerId}/currencyIcon/upload`,
      'POST',
      formData,
      null,
      'multipart/form-data'
    );

    await dispatch(
      modifyItemAction({
        id: trafficPartnerId,
        newProperties: {
          virtualCurrency: {
            ...virtualCurrencyObj,
            icon: response.data.url
          },
          removeCurrencyIcon: false,
          version: response.version,
          id: response.id
        }
      })
    );
    return response;
  } catch (err) {
    return {
      isError: true,
      primary: `Unable to upload currency icon.`,
      secondary: err.Message
    };
  }
}
// Extracts CSS selectors from the AD_UNIT_THEME object and formats them
export const extractSelectors = theme => {
  const selectors = new Set();

  const processNode = node => {
    // Early return for null/undefined or non-object values
    if (!node || typeof node !== 'object') return;

    // Handle CSS rules
    if (node.css) {
      const cssRules = Array.isArray(node.css) ? node.css : [node.css];
      cssRules.forEach(rule => rule?.selector && selectors.add(rule.selector));
      return;
    }

    // Process nested values
    Object.values(node).forEach(value => {
      if (typeof value === 'function') {
        try {
          const result = value();
          processNode(result);
        } catch (error) {
          console.warn('Failed to process function in theme:', error);
        }
      } else {
        processNode(value);
      }
    });
  };

  processNode(theme);

  // Format selectors
  return Array.from(selectors)
    .filter(Boolean)
    .map(selector => {
      const cleanSelector = selector.trim();
      return {
        label: cleanSelector.replace(/^\./, ''), // Remove leading dot
        type: cleanSelector,
        apply: `${cleanSelector} {\n\n}`
      };
    });
};
