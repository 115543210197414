import React, { useState } from 'react';
import { Box, Text, TextInput, Grid, Tip } from 'grommet';
import { getSuggestions } from '../../components/item-info';
import ItemTagSelector from '../../components/item-tag-selector';
import UserSelector from '../../components/user-selector';
import {
  FTSelect,
  LabeledInput,
  FTTextInput,
  FTTagInput,
  FTTextArea
} from '../../components/inputs';
import * as itemTypes from '../../items/item-types';
import DeliveryCaps from '../cap-strategy/delivery-caps.jsx';
import { AdvertiserInput } from '../advertiser-input';
import { EPCOverride } from '../epc-override';
import PrePingIntegrations from '../pre-ping-integrations';
import VerticalSelector from '../../components/vertical-selector.jsx';
import CampaignTypeSelector from '../campaign-type-selector.jsx';
import CustomerSuppressionSelector from '../customer-suppression-selector.jsx';

const CAMPAIGN_GOALS = [
  {
    name: 'CPA',
    type: 'CPA'
  },
  {
    name: 'CPC',
    type: 'CPC'
  },
  {
    name: 'CPM',
    type: 'CPM'
  },
  {
    name: 'CPI',
    type: 'CPI'
  },
  {
    name: 'CPE',
    type: 'CPE'
  }
];

export const TEMPORARY_SUPPRESSION = 'temporary';
const SUPPRESSION_OPTIONS = [
  {
    label: 'Never',
    value: 'permanent'
  },
  {
    label: 'After',
    value: TEMPORARY_SUPPRESSION
  }
];

const CampaignInfo = props => {
  const { item, modifyItem, canEdit, addError } = props;
  const flowIds = Array.isArray(item.flowIds) ? item.flowIds : [];
  const [suggestions, setSuggestions] = useState([]);
  const [lookBackWindowInDays, setLookBackWindowInDays] = useState(
    item.lookBackWindowInDays || 7
  );

  return (
    <Box fill overflow={{ vertical: 'auto' }} pad='medium'>
      <Grid
        columns={['repeat(3, minmax(250px, 1fr))']}
        gap='medium'
        justifyContent='start'
      >
        <Box gap='medium'>
          <FTTextInput
            label='Name'
            disabled={!canEdit}
            value={item.name || ''}
            onChange={newValue => modifyItem({ name: newValue })}
          />
          <Tip content='This is the name external partners will use in campaign tracking.'>
            <Box>
              <FTTextInput
                label='External Campaign Name'
                disabled={!canEdit}
                value={item.externalName || ''}
                onChange={newValue => modifyItem({ externalName: newValue })}
              />
            </Box>
          </Tip>
          <FTTagInput
            label='Tags'
            disabled={!canEdit}
            tags={item.categories}
            onChange={newValue => modifyItem({ categories: newValue })}
            suggestions={suggestions}
            onTextChange={text =>
              getSuggestions(text, item.categories, setSuggestions)
            }
          />
          <CampaignTypeSelector
            campaignType={item.campaignType}
            disabled={!canEdit}
            modifyItem={modifyItem}
            addError={addError}
          />
          <CustomerSuppressionSelector
            emailSuppressionListId={item.emailSuppressionListId}
            advertiserId={item.advertiserId}
            disabled={!canEdit}
            modifyItem={modifyItem}
            addError={addError}
          />
        </Box>
        <FTTextArea
          height='100%'
          label='Description'
          disabled={!canEdit}
          value={item.description || ''}
          onChange={newValue => {
            modifyItem({ description: newValue });
          }}
        />

        <Box gap='medium'>
          <UserSelector
            itemUserName={item.accountManagerName}
            itemUserId={item.accountManagerUserId}
            onChange={({ id }) =>
              modifyItem({
                accountManagerUserId: id
              })
            }
            userRole='account_manager'
            label='Account Manager'
            addError={addError}
          />
          <AdvertiserInput
            item={item}
            canEdit={canEdit}
            modifyItem={modifyItem}
          />

          <FTSelect
            label='Goal'
            value={CAMPAIGN_GOALS.find(cg => cg.type === item.goal)}
            options={CAMPAIGN_GOALS}
            labelKey='name'
            valueKey='type'
            disabled={!canEdit}
            onChange={newValue => {
              modifyItem({ goal: newValue.type });
            }}
          />
          <FTTextInput
            disabled={!canEdit}
            label='Conversion Lookback Window (days)'
            type='number'
            min={1}
            max={31}
            value={lookBackWindowInDays}
            title='Metric calculation lookback window in 1 to 31 days. Default is 7 days.'
            onChange={value => {
              const newValue = parseInt(value);
              modifyItem({ lookBackWindowInDays: newValue });
              setLookBackWindowInDays(newValue);
            }}
            tipContent='Metric calculation lookback window in 1 to 31 days. Default is 7 days.'
            onBlur={e => {
              const newValue = e.target.value;
              if (newValue < 1 || newValue > 31) {
                modifyItem({ lookBackWindowInDays: 7 });
                setLookBackWindowInDays(7);
              }
            }}
          />
          <VerticalSelector
            disabled={!canEdit}
            verticalId={item.verticalId}
            subVerticalId={item.subVerticalId}
            onChange={modifyItem}
            addError={addError}
          />
        </Box>
        <Box
          margin={{ vertical: 'medium' }}
          border={{ side: 'top', color: 'border400' }}
          gridArea='auto/span 3'
        />

        {/* Flows */}
        <Box gridArea='auto/span 3'>
          <LabeledInput label='Flows'>
            <ItemTagSelector
              itemType={itemTypes.FLOW}
              itemIds={flowIds}
              disabled={!canEdit}
              onItemAdd={itemId => {
                const newFlowIds = [...flowIds];
                newFlowIds.push(itemId);
                modifyItem({ flowIds: newFlowIds });
              }}
              onItemRemove={itemId => {
                const newFlowIds = [...flowIds];
                newFlowIds.splice(newFlowIds.indexOf(itemId), 1);
                modifyItem({ flowIds: newFlowIds });
              }}
            />
          </LabeledInput>
        </Box>

        {/* Scrub Rate */}
        <FTTextInput
          disabled={!canEdit}
          label='Scrub Rate (%)'
          type='number'
          min={0}
          value={item.scrubRate}
          title='Certain % of revenue that will be scrubbed by the advertiser.'
          onChange={newValue => {
            modifyItem({ scrubRate: newValue });
          }}
        />

        {/* EPC Manual Override*/}
        <EPCOverride onChange={modifyItem} item={item} />

        {/* Credit Delay (min) */}
        <FTTextInput
          disabled={!canEdit}
          label='Credit Delay (min)'
          type='number'
          value={item.creditDelay || ''}
          title='Delay in minutes before a user will receive credit for converting this Campaign.'
          onChange={newValue => {
            modifyItem({ creditDelay: parseInt(newValue, 10) });
          }}
        />

        {/* Allow Repeat Conversions */}
        <Box gap='xsmall' gridArea='5/2' direction='row'>
          <Box style={{ width: '180px' }}>
            <Text
              margin={{
                top: 'small'
              }}
            >
              Allow Repeat Conversions
            </Text>
          </Box>
          <Box gap='xsmall'>
            <FTSelect
              style={{ width: '100px' }}
              disabled={!canEdit}
              value={SUPPRESSION_OPTIONS.find(
                cg => cg.value === item.suppressionType
              )}
              options={SUPPRESSION_OPTIONS}
              labelKey='label'
              valueKey='value'
              onChange={newValue =>
                modifyItem({ suppressionType: newValue.value })
              }
            />
            {item.suppressionType === TEMPORARY_SUPPRESSION && (
              <Box direction='row' align='center'>
                <TextInput
                  min={1}
                  max={365}
                  type='number'
                  disabled={!canEdit}
                  style={{ width: '90px' }}
                  value={item?.repeatConversionAfterDays ?? 0}
                  onChange={e =>
                    modifyItem({ repeatConversionAfterDays: e.target.value })
                  }
                />
                <Text
                  margin={{
                    left: 'xsmall'
                  }}
                >
                  Days
                </Text>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          margin={{ vertical: 'medium' }}
          border={{ side: 'top', color: 'border400' }}
          gridArea='auto/span 3'
        />

        <Box gridArea='auto/span 2'>
          <DeliveryCaps
            onChange={modifyItem}
            caps={{
              dailyConversionCap: item.dailyConversionCap,
              dailyRevenueCap: item.dailyRevenueCap,
              monthlyConversionCap: item.monthlyConversionCap,
              monthlyRevenueCap: item.monthlyRevenueCap,
              overallConversionCap: item.overallConversionCap,
              overallRevenueCap: item.overallRevenueCap,
              capStrategy: item.capStrategy
            }}
            counts={item.capStats || {}}
            isDirty={item.isDirty}
          />
        </Box>

        <Box
          margin={{ vertical: 'medium' }}
          border={{ side: 'top', color: 'border400' }}
          gridArea='auto/span 3'
        />

        <Box margin={{ bottom: 'large' }}>
          <PrePingIntegrations
            item={item}
            canEdit={canEdit}
            modifyItem={modifyItem}
            addError={addError}
          />
        </Box>
      </Grid>
    </Box>
  );
};

CampaignInfo.displayName = 'CampaignInfo';

export default CampaignInfo;
