import { authorizedFetch } from '../utils';
import { searchItemsByType } from '../items/items.actions';
import * as itemTypes from '../items/item-types';
import { debounce } from 'lodash';
import { addError } from '../toasts/toasts.actions';

export const DEFAULT_END_DATE_STRING = '0001-01-01T00:00:00';

export const PAGE_SIZE = 20;
export const GOAL_TYPE_CPC = 'CPC';
export const GOAL_TYPE_CPA = 'CPA';
export const JOB_CAMPAIGN_TYPE = 'jobcampaign';

export const CAP_TYPES = {
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  OVERALL: 'OVERALL'
};

export const DELIVERY_CAPS = {
  HOURLY: {
    label: 'Hourly',
    description: 'Will reset hourly'
  },
  DAILY: {
    label: 'Daily',
    description: 'Will reset at 00:00 EST daily'
  },
  MONTHLY: {
    label: 'Monthly',
    description: 'Will reset on the 1st of the month'
  },
  OVERALL: {
    label: 'Overall',
    description: 'Delivery will deactivate when the overall cap is met'
  }
};

export const getCapValidationMessage = ({
  hourlyCap,
  dailyCap,
  monthlyCap,
  overallCap
}) => {
  if (dailyCap && dailyCap < hourlyCap) {
    return 'Daily cap cannot be less than hourly cap';
  }

  if (monthlyCap) {
    if (hourlyCap > monthlyCap) {
      return 'Hourly cap cannot be greater than monthly cap';
    } else if (dailyCap > monthlyCap) {
      return 'Daily cap cannot be greater than monthly cap';
    }
  }
  if (overallCap) {
    if (hourlyCap > overallCap) {
      return 'Hourly cap cannot be greater than overall cap';
    } else if (dailyCap > overallCap) {
      return 'Daily cap cannot be greater than overall cap';
    } else if (monthlyCap > overallCap) {
      return 'Monthly cap cannot be greater than overall cap';
    }
  }
};

export const updateCampaignStatus = (
  campaignId,
  campaignVersion,
  desiredStatus,
  endpoint
) => {
  return authorizedFetch(
    `/api/${endpoint}/${campaignId}/${
      desiredStatus === 'Active' ? 'activate' : 'deactivate'
    }`,
    'POST',
    {
      expectedVersion: campaignVersion
    }
  );
};

export const getCampaigns = debounce(
  (campaignType, searchValue, columnSort, pageNumber, onSuccess, onError) => {
    searchItemsByType({
      itemTypes: [campaignType],
      query: searchValue,
      sort: {
        [columnSort.field]: columnSort.direction
      },
      from: pageNumber * PAGE_SIZE,
      size: PAGE_SIZE
    })
      .then(({ items, totalMatches }) => {
        onSuccess(items, totalMatches);
      })
      .catch(err => {
        onError(
          'Unable to retrieve Items',
          err && err.message ? err.message : JSON.stringify(err)
        );
      });
  },
  200
);

export const generateGridData = (
  campaigns = [],
  reportData = [],
  defaultData,
  priorityArray
) => {
  return campaigns.map(c => {
    if (c.type === itemTypes.CAMPAIGN) {
      if (c.isActive) {
        c.status = 'Active';
      } else {
        c.status = 'Inactive';
      }
    } else if (c.type === itemTypes.DATA_CAMPAIGN) {
      if (Array.isArray(c.dataDeliveries)) {
        if (c.dataDeliveries.find(d => d.status === 'Active')) {
          c.status = 'Active';
        } else {
          c.status = 'Inactive';
        }
      }
    }

    if (c.type === itemTypes.DATA_DELIVERY_GROUP) {
      const groupReportData =
        reportData.find(r => r.campaignId === c.id) || defaultData;

      const groupPriority =
        priorityArray.findIndex(id => id === c.id) + 1 || 'Unordered';

      return {
        campaign: c,
        ...groupReportData,
        order: groupPriority
      };
    } else {
      const campaignReportData =
        reportData.find(r => r.campaignId === c.id) || defaultData;

      return {
        campaign: c,
        ...campaignReportData
      };
    }
  });
};

export const getDeliveryCapLabel = item => {
  if (item?.capStats) {
    const { dailyCapped, monthlyCapped, overallCapped } = item?.capStats;
    if (overallCapped) {
      return DELIVERY_CAPS[CAP_TYPES.OVERALL]?.label;
    }
    if (monthlyCapped) {
      return DELIVERY_CAPS[CAP_TYPES.MONTHLY]?.label;
    }
    if (dailyCapped) {
      return DELIVERY_CAPS[CAP_TYPES.DAILY]?.label;
    }
  }
  return '';
};

export const getNetsuiteLineOptions = async (item, addError) => {
  let options;
  const defaultOptions = [{ label: 'No Line Items Found', value: undefined }];

  try {
    const response = await authorizedFetch(
      `api/Netsuite/advertisers/${item.advertiserId}`,
      'GET'
    );
    if (response.length > 0) {
      options = [{ label: 'No Line Selected', value: undefined }];
      response.forEach(item => {
        options.push({
          value: item.netsuiteAdvertiserLineItem.agencyLineId,
          label: item.netsuiteAdvertiserLineItem.agencyLineId,
          isInUse: item.isInUse
        });
      });
    } else {
      options = defaultOptions;
    }
  } catch (err) {
    options = defaultOptions;
    addError('Unable to get Netsuite line items', err.message);
  }

  return options;
};

export const getConversionMappingOptions = (
  conversionMappings,
  campaignGoal
) => {
  const options = [...conversionMappings];
  if (campaignGoal === GOAL_TYPE_CPC) {
    options.unshift({
      // Hard coded ID to match backend.
      id: '00000000-0000-0000-0000-000000000001',
      displayName: 'Click',
      conversionTypeIntegrationId: 'click'
    });
  }
  return options;
};

export const getAdgroupImpressions = (
  id,
  itemType,
  setAdGroupImpressions,
  addError
) => {
  const query = {
    query: {
      bool: {
        filter: [
          {
            term: {
              eventType: 'view'
            }
          },
          {
            term: {
              'target.itemType.keyword': itemType
            }
          },
          {
            term: {
              'properties.adGroupId': id
            }
          },
          {
            range: {
              timeStamp: {
                gte: 'now-7d/d',
                lte: 'now/d',
                time_zone: 'America/New_York'
              }
            }
          }
        ]
      }
    }
  };

  authorizedFetch(
    'api/cds-search/reporting-event-*/_count?ignore_unavailable=true',
    'POST',
    query
  )
    .then(response => {
      setAdGroupImpressions(response.count);
    })
    .catch(e => addError('Error while getting Adgroup impressions', e.Message));
};

export const fetchCampaignAdGroups = async (
  campaignId,
  body = {
    from: 0,
    size: 1000,
    orderByField: 'dateModified',
    orderByAsc: false
  }
) => {
  try {
    const response = await authorizedFetch(
      `/api/${itemTypes.CAMPAIGN}/${campaignId}/${itemTypes.AD_GROUP}/search`,
      'POST',
      body
    );
    return response.items || [];
  } catch (err) {
    addError('Unable to get Ad Groups', err.message);
  }
};

export const fetchCampaignAdCreatives = async (
  campaignId,
  body = {
    from: 0,
    size: 1000,
    orderByField: 'dateModified',
    orderByAsc: false
  }
) => {
  try {
    const response = await authorizedFetch(
      `/api/${itemTypes.CAMPAIGN}/${campaignId}/${itemTypes.AD_CREATIVE}/search`,
      'POST',
      body
    );
    return response?.items || [];
  } catch (err) {
    addError('Unable to get Ad Creatives', err.message);
  }
};

// Fetch Email Suppression List
export const fetchEmailSuppressionList = async (
  advertiserId,
  body = {
    from: 0,
    size: 1000,
    orderByField: 'dateModified',
    orderByAsc: false
  }
) => {
  const response = await authorizedFetch(
    `/api/${itemTypes.ADVERTISER}/${advertiserId}/${itemTypes.EMAIL_SUPPRESSION_LIST}/search`,
    'POST',
    body
  );
  return response.items || [];
};
