import React, { Suspense } from 'react';
import { Ad, Home } from 'grommet-icons';
import * as itemTypes from '../items/item-types';
import Loader from '../components/loader';
import { canRead } from '../auth';
import {
  platforms,
  isApplicable
} from '../environment-config/environment-config';

const itemTypeValues = Object.values(itemTypes).filter(
  v => typeof v === 'string'
);

const suspenseWrap = Component => {
  return (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );
};

const lazyComponents = {
  Home: suspenseWrap(React.lazy(() => import('../home/home.container'))),
  Ads: suspenseWrap(React.lazy(() => import('../ads/ads.container'))),
  Campaign: suspenseWrap(
    React.lazy(() => import('../campaigns/performance/campaign.container'))
  ),
  Campaigns: suspenseWrap(
    React.lazy(() => import('../campaigns/performance/campaigns.container'))
  ),
  Advertiser: suspenseWrap(
    React.lazy(() => import('../advertisers/advertiser.container'))
  ),
  Advertisers: suspenseWrap(
    React.lazy(() => import('../advertisers/advertisers.container'))
  ),
  Integration: suspenseWrap(
    React.lazy(() => import('../advertisers/integration/integration.container'))
  ),
  AdCreative: suspenseWrap(
    React.lazy(() =>
      import('../campaigns/performance/ad-creatives/ad-creative.container')
    )
  ),
  AdGroup: suspenseWrap(
    React.lazy(() =>
      import('../campaigns/performance/ad-groups/ad-group.container')
    )
  ),
  DataCampaign: suspenseWrap(
    React.lazy(() => import('../campaigns/data/data-campaign.container'))
  ),
  DataCampaigns: suspenseWrap(
    React.lazy(() => import('../campaigns/data/data-campaigns.container'))
  ),
  DataDelivery: suspenseWrap(
    React.lazy(() =>
      import('../campaigns/data/delivery/data-delivery.container')
    )
  ),
  DataDeliveryGroup: suspenseWrap(
    React.lazy(() =>
      import('../campaigns/data/delivery-groups/delivery-group.container')
    )
  ),
  DataDeliveryGroups: suspenseWrap(
    React.lazy(() =>
      import('../campaigns/data/delivery-groups/delivery-groups.container')
    )
  ),
  JobCampaign: suspenseWrap(
    React.lazy(() => import('../campaigns/job/job-campaign.container'))
  ),
  JobCampaigns: suspenseWrap(
    React.lazy(() => import('../campaigns/job/job-campaigns.container'))
  ),
  CallCampaign: suspenseWrap(
    React.lazy(() => import('../campaigns/call/call-campaign.container'))
  ),
  CallCampaigns: suspenseWrap(
    React.lazy(() => import('../campaigns/call/call-campaigns.container'))
  ),
  Rule: suspenseWrap(React.lazy(() => import('../rules/rule.container'))),
  Rules: suspenseWrap(React.lazy(() => import('../rules/rules.container'))),
  AudiencePriority: suspenseWrap(
    React.lazy(() =>
      import('../audience-priorities/audience-priority.container')
    )
  ),
  AudiencePriorities: suspenseWrap(
    React.lazy(() =>
      import('../audience-priorities/audience-priorities.container')
    )
  ),
  Audience: suspenseWrap(
    React.lazy(() => import('../audiences/audience.container'))
  ),
  Audiences: suspenseWrap(
    React.lazy(() => import('../audiences/audiences.container'))
  ),
  Flow: suspenseWrap(React.lazy(() => import('../flows/flow.container'))),
  Flows: suspenseWrap(React.lazy(() => import('../flows/flows.container'))),
  Survey: suspenseWrap(React.lazy(() => import('../survey/survey.container'))),
  Surveys: suspenseWrap(
    React.lazy(() => import('../survey/surveys.container'))
  ),
  Page: suspenseWrap(React.lazy(() => import('../pages/page.container'))),
  Pages: suspenseWrap(React.lazy(() => import('../pages/pages.container'))),
  Content: suspenseWrap(
    React.lazy(() => import('../content-blocks/content-block.container'))
  ),
  Contents: suspenseWrap(
    React.lazy(() => import('../content-blocks/content-blocks.container'))
  ),
  UserAttribute: suspenseWrap(
    React.lazy(() => import('../user-attributes/user-attribute.container'))
  ),
  UserAttributes: suspenseWrap(
    React.lazy(() => import('../user-attributes/user-attributes.container'))
  ),
  ProfileAttribute: suspenseWrap(
    React.lazy(() =>
      import('../profile-attributes/profile-attribute.container')
    )
  ),
  ProfileAttributes: suspenseWrap(
    React.lazy(() =>
      import('../profile-attributes/profile-attributes.container')
    )
  ),
  Reward: suspenseWrap(React.lazy(() => import('../rewards/reward.container'))),
  Rewards: suspenseWrap(
    React.lazy(() => import('../rewards/rewards.container'))
  ),
  Report: suspenseWrap(React.lazy(() => import('../reports/report.container'))),
  Reports: suspenseWrap(
    React.lazy(() => import('../reports/reports.container'))
  ),
  JobsBrowser: suspenseWrap(
    React.lazy(() => import('../jobs-browser/jobs-browser.container'))
  ),
  TrafficSource: suspenseWrap(
    React.lazy(() =>
      import('../traffic/traffic-sources/traffic-source.container')
    )
  ),
  TrafficSources: suspenseWrap(
    React.lazy(() =>
      import('../traffic/traffic-sources/traffic-sources.container')
    )
  ),
  EmbeddedFlowConfig: suspenseWrap(
    React.lazy(() =>
      import(
        '../traffic/traffic-partners/embedded-flow-config/embedded-flow-config.container'
      )
    )
  ),
  OfferSyndicationConfig: suspenseWrap(
    React.lazy(() =>
      import(
        '../traffic/traffic-partners/offer-syndication-config/offer-syndication-config.container'
      )
    )
  ),
  TrafficPartner: suspenseWrap(
    React.lazy(() =>
      import('../traffic/traffic-partners/traffic-partner.container')
    )
  ),
  TrafficPartners: suspenseWrap(
    React.lazy(() =>
      import('../traffic/traffic-partners/traffic-partners.container')
    )
  ),
  MinionLog: suspenseWrap(React.lazy(() => import('../log/log.container'))),
  CampaignPlacements: suspenseWrap(
    React.lazy(() =>
      import('../campaigns/placement/campaign-placements.container')
    )
  ),
  CampaignPlacement: suspenseWrap(
    React.lazy(() =>
      import('../campaigns/placement/campaign-placement.container')
    )
  )
};

const navItems = {
  HOME: {
    label: 'Home',
    path: '/',
    icon: <Home />,
    component: lazyComponents.Home
  },
  ADS: {
    label: 'Ad Inventory',
    path: `/${itemTypes.AD}/:id/*`,
    icon: <Ad />,
    component: lazyComponents.Ads
  },
  CAMPAIGNS: {
    label: 'Campaigns',
    icon: itemTypes.itemTypeIcons[itemTypes.CAMPAIGN]
  },
  CAMPAIGN: {
    label: 'Campaign',
    path: `/${itemTypes.CAMPAIGN}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.CAMPAIGN],
    component: lazyComponents.Campaign
  },
  DATA_CAMPAIGN: {
    label: 'Data Campaign',
    path: `/${itemTypes.DATA_CAMPAIGN}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.DATA_CAMPAIGN],
    component: lazyComponents.DataCampaign
  },
  DATA_CAMPAIGNS: {
    label: 'Data Campaigns',
    path: `/${itemTypes.DATA_CAMPAIGN}`,
    icon: itemTypes.itemTypeIcons[itemTypes.DATA_CAMPAIGN],
    component: lazyComponents.DataCampaigns
  },
  DATA_DELIVERY: {
    label: 'Data Delivery',
    path: `/${itemTypes.DATA_CAMPAIGN}/:campaignid/${itemTypes.DATA_DELIVERY}/:id/*`,
    resolve: [itemTypes.DATA_CAMPAIGN, itemTypes.DATA_DELIVERY],
    icon: itemTypes.itemTypeIcons[itemTypes.DATA_DELIVERY],
    component: lazyComponents.DataDelivery
  },
  DATA_DELIVERY_GROUP: {
    label: 'Data Delivery Group',
    path: `/${itemTypes.DATA_DELIVERY_GROUP}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.DATA_DELIVERY_GROUP],
    component: lazyComponents.DataDeliveryGroup
  },
  DATA_DELIVERY_GROUPS: {
    label: 'Data Delivery Groups',
    path: `/${itemTypes.DATA_DELIVERY_GROUP}`,
    icon: itemTypes.itemTypeIcons[itemTypes.DATA_DELIVERY_GROUP],
    component: lazyComponents.DataDeliveryGroups
  },
  JOB_CAMPAIGN: {
    label: 'Job Campaign',
    path: `/${itemTypes.JOB_CAMPAIGN}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.JOB_CAMPAIGN],
    component: lazyComponents.JobCampaign
  },
  JOB_CAMPAIGNS: {
    label: 'Job Campaigns',
    path: `/${itemTypes.JOB_CAMPAIGN}`,
    icon: itemTypes.itemTypeIcons[itemTypes.JOB_CAMPAIGN],
    component: lazyComponents.JobCampaigns
  },
  CALL_CAMPAIGN: {
    label: 'Call Campaign',
    path: `/${itemTypes.CALL_CAMPAIGN}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.CALL_CAMPAIGN],
    component: lazyComponents.CallCampaign
  },
  CALL_CAMPAIGNS: {
    label: 'Call Campaigns',
    path: `/${itemTypes.CALL_CAMPAIGN}`,
    icon: itemTypes.itemTypeIcons[itemTypes.CALL_CAMPAIGN],
    component: lazyComponents.CallCampaigns
  },
  RULE: {
    label: 'Rule',
    path: `/${itemTypes.RULE}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.RULE],
    component: lazyComponents.Rule
  },
  RULES: {
    label: 'Rules',
    path: `/${itemTypes.RULE}`,
    icon: itemTypes.itemTypeIcons[itemTypes.RULE],
    component: lazyComponents.Rules
  },
  PERFORMANCE_CAMPAIGNS: {
    label: 'Performance Campaigns',
    path: `/${itemTypes.CAMPAIGN}`,
    icon: itemTypes.itemTypeIcons[itemTypes.CAMPAIGN],
    component: lazyComponents.Campaigns
  },
  ADVERTISER: {
    label: 'Advertiser',
    path: `/${itemTypes.ADVERTISER}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.ADVERTISER],
    component: lazyComponents.Advertiser
  },
  ADVERTISERS: {
    label: 'Advertisers',
    path: `/${itemTypes.ADVERTISER}`,
    icon: itemTypes.itemTypeIcons[itemTypes.ADVERTISER],
    component: lazyComponents.Advertisers,
    parentItem: 'ADVERTISEMENTS'
  },
  INTEGRATION: {
    label: 'Integration',
    path: `/${itemTypes.ADVERTISER}/:advertiserid/${itemTypes.INTEGRATION}/:id/*`,
    resolve: [itemTypes.ADVERTISER, itemTypes.INTEGRATION],
    icon: itemTypes.itemTypeIcons[itemTypes.INTEGRATION],
    component: lazyComponents.Integration
  },
  AD_CREATIVE: {
    label: 'Ad Creative',
    path: `/${itemTypes.CAMPAIGN}/:campaignid/${itemTypes.AD_CREATIVE}/:id/*`,
    resolve: [itemTypes.CAMPAIGN, itemTypes.AD_CREATIVE],
    icon: itemTypes.itemTypeIcons[itemTypes.AD_CREATIVE],
    component: lazyComponents.AdCreative
  },
  AD_GROUP: {
    label: 'Ad Group',
    path: `/${itemTypes.CAMPAIGN}/:campaignid/${itemTypes.AD_GROUP}/:id/*`,
    resolve: [itemTypes.CAMPAIGN, itemTypes.AD_GROUP],
    icon: itemTypes.itemTypeIcons[itemTypes.AD_GROUP],
    component: lazyComponents.AdGroup
  },
  AUDIENCE: {
    label: 'Audience',
    path: `/${itemTypes.AUDIENCE}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.AUDIENCE],
    component: lazyComponents.Audience
  },
  AUDIENCES: {
    label: 'Audiences',
    path: `/${itemTypes.AUDIENCE}`,
    icon: itemTypes.itemTypeIcons[itemTypes.AUDIENCE],
    component: lazyComponents.Audiences
  },
  AUDIENCE_PRIORITIES: {
    label: 'Audience Priorities',
    path: `/${itemTypes.AUDIENCE_PRIORITY}`,
    icon: itemTypes.itemTypeIcons[itemTypes.AUDIENCE_PRIORITY],
    component: lazyComponents.AudiencePriorities,
    parentItem: 'ADVERTISEMENTS'
  },
  AUDIENCE_PRIORITY: {
    label: 'Audience Priority',
    path: `/${itemTypes.AUDIENCE_PRIORITY}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.AUDIENCE_PRIORITY],
    component: lazyComponents.AudiencePriority,
    parentItem: 'ADVERTISEMENTS'
  },
  FLOW: {
    label: 'Flow',
    path: `/${itemTypes.FLOW}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.FLOW],
    component: lazyComponents.Flow
  },
  FLOWS: {
    label: 'Flows',
    path: `/${itemTypes.FLOW}`,
    icon: itemTypes.itemTypeIcons[itemTypes.FLOW],
    component: lazyComponents.Flows
  },
  SURVEY: {
    label: 'Survey',
    path: `/${itemTypes.SURVEY}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.SURVEY],
    component: lazyComponents.Survey
  },
  SURVEYS: {
    label: 'Surveys',
    path: `/${itemTypes.SURVEY}`,
    icon: itemTypes.itemTypeIcons[itemTypes.SURVEY],
    component: lazyComponents.Surveys
  },
  PAGE: {
    label: 'Page',
    path: `/${itemTypes.PAGE}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.PAGE],
    component: lazyComponents.Page
  },
  PAGES: {
    label: 'Pages',
    path: `/${itemTypes.PAGE}`,
    icon: itemTypes.itemTypeIcons[itemTypes.PAGE],
    component: lazyComponents.Pages
  },
  CONTENT_BLOCK: {
    label: 'Content',
    path: `/${itemTypes.CONTENT_BLOCK}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.CONTENT_BLOCK],
    component: lazyComponents.Content
  },
  CONTENTS: {
    label: 'Contents',
    path: `/${itemTypes.CONTENT_BLOCK}`,
    icon: itemTypes.itemTypeIcons[itemTypes.CONTENT_BLOCK],
    component: lazyComponents.Contents,
    parentItem: 'PAGES'
  },
  USER_ATTRIBUTE: {
    label: 'User Attribute',
    path: `/${itemTypes.USER_ATTRIBUTE}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.USER_ATTRIBUTE],
    component: lazyComponents.UserAttribute
  },
  USER_ATTRIBUTES: {
    label: 'User Attributes',
    path: `/${itemTypes.USER_ATTRIBUTE}`,
    icon: itemTypes.itemTypeIcons[itemTypes.USER_ATTRIBUTE],
    component: lazyComponents.UserAttributes,
    parentItem: 'SURVEYS'
  },
  PROFILE_ATTRIBUTE: {
    label: 'Profile Attribute',
    path: `/${itemTypes.PROFILE_ATTRIBUTE}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.PROFILE_ATTRIBUTE],
    component: lazyComponents.ProfileAttribute
  },
  PROFILE_ATTRIBUTES: {
    label: 'Profile Attributes',
    path: `/${itemTypes.PROFILE_ATTRIBUTE}`,
    icon: itemTypes.itemTypeIcons[itemTypes.PROFILE_ATTRIBUTE],
    component: lazyComponents.ProfileAttributes
  },
  REWARD: {
    label: 'Reward',
    path: `/${itemTypes.REWARD}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.REWARD],
    component: lazyComponents.Reward
  },
  REWARDS: {
    label: 'Rewards',
    path: `/${itemTypes.REWARD}`,
    icon: itemTypes.itemTypeIcons[itemTypes.REWARD],
    component: lazyComponents.Rewards
  },
  REPORT: {
    label: 'Report',
    path: `/${itemTypes.REPORT}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.REPORT],
    component: lazyComponents.Report
  },
  REPORTS: {
    label: 'Reports',
    path: `/${itemTypes.REPORT}`,
    icon: itemTypes.itemTypeIcons[itemTypes.REPORT],
    component: lazyComponents.Reports
  },
  JOBS_BROWSER: {
    label: 'Jobs Browser',
    path: `/${itemTypes.JOBS_BROWSER}`,
    icon: itemTypes.itemTypeIcons[itemTypes.JOBS_BROWSER],
    component: lazyComponents.JobsBrowser
  },
  TRAFFIC: {
    label: 'Traffic',
    path: `/${itemTypes.TRAFFIC_SOURCE}`,
    icon: itemTypes.itemTypeIcons[itemTypes.TRAFFIC_SOURCE],
    component: lazyComponents.TrafficSources
  },
  TRAFFIC_SOURCE: {
    label: 'Traffic Source',
    path: `/${itemTypes.TRAFFIC_SOURCE}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.TRAFFIC_SOURCE],
    component: lazyComponents.TrafficSource
  },
  TRAFFIC_SOURCES: {
    label: 'Traffic Sources',
    path: `/${itemTypes.TRAFFIC_SOURCE}`,
    icon: itemTypes.itemTypeIcons[itemTypes.TRAFFIC_SOURCE],
    component: lazyComponents.TrafficSources
  },
  AD_UNIT_CONFIG: {
    label: 'Ad Unit Config',
    path: `/${itemTypes.TRAFFIC_PARTNER}/:trafficpartnerid/${itemTypes.EMBEDDED_FLOW_CONFIG}/:id/*`,
    resolve: [itemTypes.TRAFFIC_PARTNER, itemTypes.EMBEDDED_FLOW_CONFIG],
    icon: itemTypes.itemTypeIcons[itemTypes.EMBEDDED_FLOW_CONFIG],
    component: lazyComponents.EmbeddedFlowConfig
  },
  TRAFFIC_PARTNER: {
    label: 'Traffic Partner',
    path: `/${itemTypes.TRAFFIC_PARTNER}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.TRAFFIC_PARTNER],
    component: lazyComponents.TrafficPartner
  },
  TRAFFIC_PARTNERS: {
    label: 'Traffic Partners',
    path: `/${itemTypes.TRAFFIC_PARTNER}`,
    icon: itemTypes.itemTypeIcons[itemTypes.TRAFFIC_PARTNER],
    component: lazyComponents.TrafficPartners
  },
  OFFER_SYNDICATION_CONFIG: {
    label: 'Offer Syndication Config',
    path: `/${itemTypes.TRAFFIC_PARTNER}/:trafficpartnerid/${itemTypes.OFFER_SYNDICATION_CONFIG}/:id/*`,
    resolve: [itemTypes.TRAFFIC_PARTNER, itemTypes.OFFER_SYNDICATION_CONFIG],
    icon: itemTypes.itemTypeIcons[itemTypes.OFFER_SYNDICATION_CONFIG],
    component: lazyComponents.OfferSyndicationConfig
  },
  MINION_LOG: {
    label: 'Log',
    path: `/${itemTypes.MINION_LOG}`,
    icon: itemTypes.itemTypeIcons[itemTypes.MINION_LOG],
    component: lazyComponents.MinionLog
  },
  CAMPAIGN_PLACEMENTS: {
    label: 'Campaign Placements',
    path: `/${itemTypes.CAMPAIGN_PLACEMENT}`,
    icon: itemTypes.itemTypeIcons[itemTypes.CAMPAIGN_PLACEMENT],
    component: lazyComponents.CampaignPlacements
  },
  CAMPAIGN_PLACEMENT: {
    label: 'Campaign Placement',
    path: `/${itemTypes.CAMPAIGN_PLACEMENT}/:id/*`,
    icon: itemTypes.itemTypeIcons[itemTypes.CAMPAIGN_PLACEMENT],
    component: lazyComponents.CampaignPlacement
  }
};

const ENABLED_ON_CDP = [
  navItems.AUDIENCES.path,
  navItems.AUDIENCE.path,
  navItems.REPORTS.path,
  navItems.REPORT.path,
  navItems.RULES.path,
  navItems.RULE.path
];

export const isAuthorizedRoute = route => {
  if (
    isApplicable({ platform: platforms.CDP }) &&
    ENABLED_ON_CDP.indexOf(route.path) === -1
  ) {
    return false;
  }
  const pathParts = route.path?.split('/');
  if (
    pathParts &&
    itemTypeValues.indexOf(pathParts[1]) !== -1 &&
    !canRead(pathParts[1])
  ) {
    return false;
  } else {
    return true;
  }
};

export default navItems;
